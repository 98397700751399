import React from "react"
import PetCard, { IPetCardProps, makePetCardProps } from "../partials/PetCard"
import YandexRtbBlock, { BlockId } from "components/lib/YandexRtbBlock"
import { ICategoryProps, CategoryLink } from "components/lib/CategoryLink"
import SocialSharer from "components/lib/SocialSharer"
import VkCommentsBrowser from "components/lib/VkCommentsBrowser"
import VkComments from "components/lib/VkComments"
import { connect } from "react-redux"
import { IAppStore } from "reducers"
import { RouteComponentProps } from "react-router"
import { ContentHeader } from "../partials/ContentHeader"
import { IAd } from "modules/ads/ads.reducers"
import { adFetch, adsIncrementCounter } from "modules/ads/ads.actions"
import getLoader from "modules/utils/loader"
import { Helmet } from "react-helmet"
import { Divider, Box, Heading, Flex } from "@chakra-ui/core"
import Button from "components/lib/Button"
import Link from "components/lib/Link"
import { originalSizeUrl } from "modules/ads/images"
import { sendAmplitudeEvent } from "modules/utils/events"
import { parseISO } from "date-fns"

interface IAdDetailStateProps extends IPetCardProps {
  id: number
  breadcrumbs: ICategoryProps[]
  socialSharingText: string
  source: string
  animalType: string
  createdAdId?: number
  finishedAt?: string
  finishedReason?: string
}

interface IAdDetailDispatchProps {
  adFetch(id: number): void
  adsIncrementCounter(ids: number[], counterType: string): void
}

interface IAdDetailProps extends IAdDetailStateProps, IAdDetailDispatchProps, RouteComponentProps<void> {}

const makeAdDetailProps = (ad: IAd): IAdDetailStateProps => {
  const s: string[] = []
  s.push(ad.description.replace(/[\.]+$/g, ""))
  if (ad.phone) {
    s.push(`. Телефон: ${ad.phone}`)
  }

  if (ad.placeDescription) {
    s.push(`. Место пропажи: ${ad.placeDescription}`)
  }

  if (ad.breed) {
    s.push(`. Порода: ${ad.breed}`)
  }

  if (ad.name) {
    s.push(`. Отзывается на: ${ad.name}`)
  }

  if (ad.signs) {
    s.push(`. Особые приметы: ${ad.signs}`)
  }

  if (ad.color) {
    s.push(`. Окрас: ${ad.color}`)
  }

  const socialSharingText = s
    .join("")
    .replace(/[\r\n]+/, ". ")
    .replace(/(\\r|\\n)+/, ". ")
    .replace(/\t/, "")
    .replace(/ \./, ".")
    .replace(/\.{2,}/, ".")

  const breadcrumbs: ICategoryProps[] = []
  if (ad.nearestPlaceName) {
    breadcrumbs.push({ name: ad.nearestPlaceName, url: `/${ad.nearestPlaceName}` })
    breadcrumbs.push({ name: ad.category, url: `/${ad.nearestPlaceName}/${ad.category}` })
    breadcrumbs.push({ name: ad.animalType, url: `/${ad.nearestPlaceName}/${ad.category}/${ad.animalType}` })
    breadcrumbs.push({ name: ad.title, url: null })
  }

  return {
    ...makePetCardProps(ad, ad.nearestPlaceName),
    breadcrumbs,
    socialSharingText,
    id: ad.id,
    source: ad.source,
    animalType: ad.animalType,
    finishedAt: ad.finishedAt,
    finishedReason: ad.finishedReason,
  }
}

class AdDetailComponent extends React.Component<IAdDetailProps> {
  constructor(props: IAdDetailProps) {
    super(props)

    // Run it from constructor because componentDidMount isn't called in SSR
    // TODO: use https://reactjs.org/docs/concurrent-mode-suspense.html
    if (!this.props.createdAt || !this.props.breadcrumbs.length) {
      this.props.adFetch(this.props.id)
      this.props.adsIncrementCounter([this.props.id], "click") // Do it only once suring SSR. TODO: move into backend
    }
  }

  public componentDidMount(): void {
    // called only on client, no server-side execution
    const now = new Date()
    const adCreatedAt = parseISO(this.props.createdAt)
    const elapsedSec = Math.round((now.getTime() - adCreatedAt.getTime()) / 1000)
    sendAmplitudeEvent("view_ad", {
      adId: this.props.id.toString(),
      place: this.props.place?.name || "",
      category: this.props.category?.name || "",
      animalType: this.props.animalType || "",
      isAdOfUser: this.props.createdAdId && this.props.createdAdId === this.props.id,
      secondsSinceCreation: elapsedSec,
    })
  }

  private renderSourceRow() {
    return (
      this.props.source &&
      !this.props.finishedAt && (
        <>
          <Divider />
          <Link isExternal href={this.props.source}>
            Источник
          </Link>
        </>
      )
    )
  }

  private renderEditButton() {
    return (
      this.props.createdAdId &&
      this.props.createdAdId === this.props.id &&
      !this.props.finishedAt && (
        <Button bg="cyan.500" color="white" marginTop={3} href={`/ads/${this.props.id}/edit`}>
          Редактировать объявление
        </Button>
      )
    )
  }

  private renderVkCommentsRow() {
    return (
      <>
        <Divider />
        <Heading as="h3" size="sm" marginBottom={1}>
          Комментарии к посту
        </Heading>
        <VkComments url={`${HOST}/ads/${this.props.id}`} />
      </>
    )
  }

  private seoDescription(): string {
    const desc = this.props.description
    const maxDescLenWithoutDots = 145
    if (desc.length <= maxDescLenWithoutDots) {
      return desc
    }

    return desc.substring(0, maxDescLenWithoutDots) + "..."
  }

  private renderHelmet() {
    const section = `${this.props.category.name} ${this.props.animalType.toLowerCase()}`

    return (
      <Helmet>
        <title>{`Объявление. ${this.props.title}`}</title>
        <meta name="description" content={this.seoDescription()} />
        <meta property="og:title" content={this.props.title} />
        <meta property="og:description" content={this.props.socialSharingText} />
        <meta property="og:type" content="article" />
        <meta property="article:published_time" content={this.props.createdAt} />
        <meta property="article:modified_time" content={this.props.createdAt} />
        <meta property="article:section" content={section} />
        {this.props.mainImage && [
          <meta key="0" property="og:image" content={`${HOST}${originalSizeUrl(this.props.mainImage)}`} />,
          <meta key="1" property="og:image:secure_url" content={`${HOST}${originalSizeUrl(this.props.mainImage)}`} />,
        ]}
      </Helmet>
    )
  }

  public render() {
    if (!this.props.createdAt) {
      return (
        <Box>
          <Heading as="h3" size="sm">
            Пожалуйста, подождите: объявление загружается...
          </Heading>
          {getLoader()}
        </Box>
      )
    }

    return (
      <>
        {this.renderHelmet()}

        <ContentHeader breadcrumbs={this.props.breadcrumbs} />
        <Box>
          <Divider />
          <Flex>
            <Box width={{ base: `100%`, md: `100%`, lg: `75%` }} boxShadow="md" padding={2}>
              <Heading as="h3">{this.props.title}</Heading>
              <PetCard {...this.props} />

              {this.renderEditButton()}
              {this.renderSourceRow()}
              <Box marginTop={2}>
                <SocialSharer
                  title={this.props.title}
                  image={this.props.mainImage ? `${HOST}${originalSizeUrl(this.props.mainImage)}` : null}
                  socialSharingText={this.props.socialSharingText}
                />
              </Box>
              <Flex>
                <Box width={{ base: `100%`, md: `100%`, lg: `80%`, xl: `50%` }}>{this.renderVkCommentsRow()}</Box>
              </Flex>
            </Box>
            <Box
              width={{ base: `0%`, md: `0%`, lg: `25%` }}
              boxShadow="md"
              padding={2}
              marginLeft={2}
              display={{ base: `none`, md: `none`, lg: `block` }}
            >
              {/* <Heading as="h5" size="sm">
                Реклама
              </Heading>
              <YandexRtbBlock id={BlockId.V2RightColumn} rerenderOnLocChange={true} /> */}
            </Box>
          </Flex>
        </Box>
        <Divider />
        <Box padding={2}>
          <Heading as="h3" size="md" marginBottom={1}>
            Последние комментарии на сайте
          </Heading>
          <Flex>
            <Box width={{ base: `100%`, md: `100%`, lg: `80%`, xl: `50%` }}>
              <VkCommentsBrowser />
            </Box>
          </Flex>
        </Box>
      </>
    )
  }
}

interface IParams {
  id: string
}

const mapDispatchToProps = {
  adFetch,
  adsIncrementCounter,
}

const mapStateToProps = ({ ads }: IAppStore, routeProps: RouteComponentProps<IParams>) => {
  const id = parseInt(routeProps.match.params.id, 10)
  const ad = ads.storage[id]
  if (!ad) {
    return {
      id,
    }
  }
  return {
    createdAdId: ads.session.createdAdId,
    ...makeAdDetailProps(ad),
  }
}

export default connect<any, any, any>(mapStateToProps, mapDispatchToProps)(AdDetailComponent)
