import React from "react"
import LazyLoad from "react-lazyload"
import VkLoader from "./VkLoader"

interface IVkCommentsProps {
  url: string
  maxHeight?: number
}

class VkComments extends React.PureComponent<IVkCommentsProps> {
  private readonly divId = "vk_comments"

  public render() {
    return (
      <>
        <div id={this.divId} />
        <VkLoader onLoad={this.initComments} />
      </>
    )
  }

  public initComments = (widgets: any) => {
    if (widgets) {
      console.log(`initializing VkComments for div "${this.divId}" and url "${this.props.url}"`)
      widgets.Comments(this.divId, {
        limit: 10,
        attach: "*",
        pageUrl: this.props.url,
        height: this.props.maxHeight || undefined,
      })
    }
  }
}

// Use lazyload not only because it's lazy but also because it doesn't render elements with hidden (display: none) parent.
// It saves us from unneeded requests on mobile devices when vk isn't visible.
export default (props: IVkCommentsProps) => (
  <LazyLoad>
    <VkComments {...props} />
  </LazyLoad>
)
