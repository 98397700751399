import React from "react"
import { Box, Divider, Heading, Flex } from "@chakra-ui/core"
import Script from "react-load-script"

const scriptAttributes = { async: false, defer: true }

interface ISocialSharerProps {
  title: string
  image: string | null
  socialSharingText: string
}

export default class SocialSharer extends React.Component<ISocialSharerProps> {
  private handleScriptError(): void {
    console.log("error loading social sharing script")
  }

  private handleScriptLoad(): void {
    const w = window as any
    w.Ya.share2("yashare", {
      content: {
        url: window.location.href,
      },
    })
  }

  public render() {
    const socialSharingSettings = {
      "data-services": "vkontakte,facebook,odnoklassniki,twitter,moimir",
      "data-counter": "",
      "data-title": this.props.title,
      "data-image": this.props.image,
      "data-description": this.props.socialSharingText,
      "data-limit": 4,
      "data-copy": "first",
    }
    return (
      <Box>
        <Divider />
        <Flex wrap="wrap">
          <Heading as="h3" size="sm" marginBottom={2} marginRight={2}>
            Поделитесь объявлением в социальных сетях
          </Heading>

          <div id="yashare" className="ya-share2" {...socialSharingSettings} />
        </Flex>
        <Script
          url="https://yastatic.net/share2/share.js"
          onError={this.handleScriptError.bind(this)}
          onLoad={this.handleScriptLoad.bind(this)}
          attributes={scriptAttributes}
        />
      </Box>
    )
  }
}
